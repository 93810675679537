.verticalNav div.links a {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  /* height: 100%; */
  text-align: center;
  padding: 8px;
  margin-bottom: 4px;
  color: #a3a3a3;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  &:hover:not(.navbar-brand) {
    color: white !important;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    background: #262626;
  }

  i {
    font-size: 13px;
  }
}
.verticalNav {
  position: relative;
  background: #18181b;
  padding-inline: 0px;
  margin: 0px;
  font-family: Clash Grotesk;
  border-right: 1px solid #262626;

  display: flex;
  flex-direction: column;
}
.verticalNav p {
  margin: 0px !important;
  padding: 0px !important;
  width: 20px;
}

.verticalNav div.close {
  display: none;
}

button.dropup-toggle {
  border: none;
  border-radius: 10px;
  height: 36px;
  min-width: 100% !important;
  font-weight: 500;
  color: #171717;
  font-size: 14px;
  letter-spacing: 0.8px;
  padding-block: 8px;
}

.dropup-content {
  display: block;
  list-style-type: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0a0a0a;
  border: 1px solid #262626;
  border-radius: 11px;

  min-width: 176px;
  z-index: 1;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);

  li {
    padding: 6px 8px;
    line-height: 1.2;
    border-radius: 8px;
    a {
      font-size: 14.5px !important;
      color: #fafafa !important;
      font-weight: 500;
      display: inline-block;
      width: 100%;
    }

    &:hover,
    &:active {
      background-color: #262626;
    }
  }
}

.active-tab {
  color: #fff !important;
  transition: all 0.2s ease-in-out;
}

.show1 {
  display: block !important;
  transition: all 0.3s ease;
}

.dropdown-menu {
  min-width: 100px !important;
  position: relative !important;
  top: -42px !important;
  left: -18px !important;
  background-color: rgb(26 27 31) !important;
}

.show {
  outline: none !important;
  border: none !important;
}
.t {
  width: 250px !important;
  /* background-color: #131313; */
  border-radius: 12px;
  color: white;
}
.t > li:focus {
  color: black;
  border-bottom: 2px solid #a2d254 !important;
  /* background-color: red; */
  outline: none;
  border: none;
  position: static;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(11 11 11 / var(--tw-text-opacity));
  background: linear-gradient(45deg, #8fd41f 0%, #ffe569 40%) !important;
  background-size: 200% auto !important;
  transition: background-position 0.5s ease !important;
}

.react-tabs__tab--selected {
  background-color: none !important;
}

.t li {
  color: white;
  background-color: #131313;
  border: none;
}

.dash-header {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #262626;
  padding: 8px 16px;
  max-height: 46px;
  .sidebar-toggle {
    width: fit-content;
    font-size: 17px;
    padding: 0px 6px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      border-radius: 10px;
      background: #262626;
    }
    .icon {
      height: 27px !important;
    }
  }

  p {
    margin-block: auto;
    font-weight: 500;
  }
}

/* alert bg color */
.userCard div.alert {
  background: #131313;
}

.userCard {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.userCard > .card {
  /* height: 100px; */
  /* width: 100%; */
  background-color: #121213 !important;
  border-radius: 24px;
  border: 1px solid #262626;
  padding: 16px;
  font-family: Clash Grotesk;

  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.icon-wrapper {
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 50%;
  background-color: #212619;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons {
  display: inline-block;
  font-size: 18px;
  color: white;
}

.userCard p {
  margin-bottom: 0px !important;
  padding-left: 0px;
}

.card p:nth-child(1) {
  font-size: 15px;
  color: #a3a3a3;
  font-weight: 500;
  line-height: 20px;
}

.card p:nth-child(2) {
  color: #a2d254;
  font-size: 26px;
  font-weight: 500;
  line-height: 2rem;
}

.card p.lh-20px {
  font-size: 15px !important;
  line-height: 25px !important;
}

.card button.btn-success {
  background-color: #a2d254;
  border: none;
  padding: 3px 12px;
  border-radius: 6px;
  font-size: 17px;

  &:hover {
    background-color: #9fcc57f7;
  }
}

img.trophy-png {
  display: inline-block;
  position: absolute;
  width: 250px;
  right: 0;
  bottom: -1.25rem;
}

.custom-gradient {
  position: absolute;
  inset: -0.125rem;
  background: linear-gradient(to right, #fb7185, #3b82f6);
  border-radius: 1rem;
  filter: blur(16px);
  opacity: 0.3;
  transition: all 1s;
  animation: shine 3s linear infinite;
  background-size: 200% auto;
}

.verticalNav div.connect-btn {
  padding-inline: 14px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(0.97);
  }
}

@media (max-width: 1024px) {
  .userCard {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .verticalNav {
    width: 287px !important;
    z-index: 555;
    /* display: none; */
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .hide {
    display: block !important;
    transition: display 0.3s ease;
  }
  .filtertable table {
    width: 600px !important;
    overflow-x: auto;
  }
  .userCard {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  img.trophy-png {
    height: 150px !important;
    width: 150px !important;
  }
}

@keyframes shine {
  0% {
    content: "";
    background-position: 0% 0%;
  }
  50% {
    content: "";
    background-position: 100% 100%;
  }
  100% {
    content: "";
    background-position: 0% 0%;
  }
}
