@font-face {
  font-family: "Clash Grotesk"; /*Can be any text*/
  src: local("ClashGrotesk-Regular"),
    url("./fonts/ClashGrotesk-Regular.ttf") format("truetype");
}
@import "https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,300,400&f[]=clash-grotesk@400,600,300,500&display=swap";

@keyframes react-loading-skeleton {
  to {
    transform: translate(100%);
  }
}
:root {
  /* --Gradient: linear-gradient(90deg, #add254 35.73%, #FFD300 40.73%); */
  scroll-behavior: smooth;
}
/* Custom scroll bar */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #313131;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #959393;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c6c4c4;
}

#tabp > button {
  border-radius: 18px !important;
  width: 80px;
  margin-left: 5px;
  height: 32px;
}

.re-top {
  border-radius: 9px !important;
  width: 150px !important;
}
.re-top:hover {
  color: #000 !important;
}
.text-gradient {
  text-align: center;
  background: linear-gradient(
      45deg,
      rgb(143, 212, 31) 0%,
      rgb(255, 229, 105) 40%
    )
    text;
  -webkit-text-fill-color: transparent;
}
.p-lg {
  text-align: center;
  color: white;
}
.form input {
  width: 100%;
  background: transparent;
  border: 2px solid rgb(49 49 49);
  border-radius: 100px;
  height: 40px;
  outline: none;
  color: white;
  padding-left: 10px;
  display: inline-block;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-duration: 0.2s;
  margin: 10px 0px;
}
.form1 input {
  width: 80%;
  background: transparent;
  border: none;
  outline: none !important;
  color: white;
  padding-left: 10px;
  display: inline-block;
  transition-duration: 0.2s;
  margin: 10px 0px;
}
.PhoneInput {
  padding-left: 8px;
}
.form-card {
  border: 2px solid rgb(29, 29, 31);
  border-radius: 12px;
  background-color: #171717;
  box-shadow: rgb(241 236 236 / 43%) 0px 14px 28px,
    rgb(251 249 249 / 6%) 0px 10px 10px;
}
.form-btn:hover {
  background-position: right center;
  color: #000 !important;
}
.form-btn {
  width: 100%;
  border-radius: 100px;
  text-align: center;
  background: linear-gradient(45deg, #8fd41f 0%, #ffe569 40%);
  background-size: 200% auto;
  transition: background-position 0.5s ease;
  font-size: 20px;
  color: #000 !important;
  height: 40px;
  padding-top: 5px;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 40px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  margin: 5px auto;
  height: 6px;
  border-radius: 10px;
  /* background-color: #1276ea; */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background-color: #000;
  border: 1px solid white;
  overflow: visible;
  cursor: pointer;
  transition: all 1s ease;
}

.row {
  margin: 0;
  padding: 0;
}
body {
  background-color: black;
  /* font-family: "Clash Grotesk"; */
}

#w1 a:hover {
  color: white !important;
}
#bnb:hover {
  color: black !important;
}
.connect-btn-contact {
  width: 100%;
  height: 100%;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: linear-gradient(90deg, #a2d254 0%, #ffd300 100%);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  display: inline-flex;
}
.footer-logo {
  display: inline-block;
}
.explore-button {
  width: 25%;
  margin-top: 30px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;

  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  ) !important;
  color: black;
  font-weight: 600;
}
.nav-tabs .nav-link {
  background: #1d1d21;
  background: var(--Primary-Dark-Cards, #1d1d21);
  color: #fff;
  font-weight: 600;
}
.nav-link {
  background-color: #1d1d21 !important;
  color: #ffffff;
  border-radius: 3px !important;
}
.nav-link:focus,
.nav-link:hover {
  color: #e4e5e6;
}
#leader th,
td {
  text-align: center;
}

.td {
  font-family: Clash Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: end;
}

/* .custom-input::placeholder {
  color: #7d7b7b!important; 
  display: none!important;
} */

@media (max-width: 1760px) {
  .input input {
    font-size: 10px;

    /* line-height: 3; */
  }
  .input span {
    background-color: #171717;
    right: 3px !important;
  }
}

@media (max-width: 1540px) {
  .farm:nth-of-type(2) {
    width: 24%;
  }
  .farm:nth-of-type(4) {
    width: 14%;
    /* display: none; */
  }
}

@media (max-width: 1310px) {
  .tb {
    width: 60% !important;
    font-size: 20px !important;
  }
}
@media (max-width: 1292px) {
  #reward > div {
    height: 250px !important;
  }
  #rewardTable {
    margin-top: 20px !important;
  }
  #rewardTable .b {
    width: 230px !important;
    /* border: 1px solid; */
  }
  #rewardTable .t {
    background-color: transparent;
  }
}

@media (max-width: 600px) {
  .explore-button {
    width: 100%;
    margin-top: 30px;
  }
  #bannerDiv div {
    margin-top: 5px;
  }
  .rs {
    margin-left: 0px;
  }
  .mob-tool {
    /* display: none !important; */
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    padding: 0px !important;
  }
  .dddd{
    /* border: 1px solid red; */
    flex-direction: column;
    align-items: flex-start !important;
  }
}
#open a:hover {
  color: black !important;
}
#crypto {
  color: #fff;

  /* Heading 1 */
  font-family: Clash Grotesk;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#crypto span {
  background: linear-gradient(90deg, #a2d254 13.39%, #ffd300 98.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Heading 1 */
  font-family: Clash Grotesk;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#link {
  color: var(--Gray-200, #c6c4c4) !important;
  text-align: center;

  /* Paragraph XX-Large */
  font-family: Satoshi;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#open {
  border-radius: 60px;
  background: var(
    --Primary-Linear-Gradient,
    linear-gradient(90deg, #a2d254 0%, #ffd300 100%)
  );
  line-height: 2;
  margin-top: 15px !important;
  padding: 6px 14px;
}
#open button {
  background-color: transparent;
  border: none;
  outline: none;
  color: #000;
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* padding-left: 5px; */
}
#left-b {
  /* border-radius: 30px; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background: linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%);
  height: 86px;
  flex-shrink: 0;
  margin-left: -25px;
}
.left-u {
  width: 140px;
  height: 88px;
  flex-shrink: 0;
  /* border-radius: 30px; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: 4px solid #2f2f2f;
  margin-left: -25px;
  margin-bottom: -40px;
}
#infoNum {
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-align: center; */

  /* Heading 2 */
  font-family: Clash Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#infoNum2 {
  color: var(--Yeti-White, #fff);

  /* Paragraph X-Large */
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#feature p {
  color: var(--Gray-300, #9b9b9b);
  text-align: center;

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#feature #div1 {
  text-align: center;

  /* Heading 2 */
  font-family: Clash Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(90deg, #a2d254 35.73%, #ffd300 40.73%);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #a2d254;
}
#feature #div2 {
  color: var(--Yeti-White, #fff);
  text-align: center;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 3px;
}

#filterTable1 {
}
#comText {
  text-align: center;

  /* Heading 2 */
  font-family: Clash Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#i3 {
  border-radius: 60px;
  background: var(--Yeti-White, #fff);
  padding: 16px 14px;
  height: 60px;
  margin-top: 10px;
}
#i3 span {
  color: #1276ea;
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#vault {
  margin: auto;
}
#close-btn {
  color: white !important;
}
#vault a {
  text-align: center;
  width: 30%;
  color: white;
  margin: 5px;
  border: 1px solid #313131;
  border-radius: 6px;
}
#openapp:hover {
  color: black !important;
}

@media (max-width: 1440px) {
  #i3 {
    line-height: 1;
  }
  #i3 span {
    font-size: 15px !important;
  }
  #harvest {
    font-size: 40px !important;
  }
}
@media (min-width: 450px) and (max-width: 992px) {
  #wallet1 .row > .col-lg-4 {
    position: relative !important;
  }
  #wallet2 {
    position: relative !important;
    right: 0 !important;
    margin-top: 10px !important;
  }
  #w {
    position: relative !important;
  }
  #wallet {
    height: auto !important;
    margin-bottom: 100px;
  }
  #logo {
    margin: 0 !important;
  }
  #logo img {
    width: 70% !important;
  }
  #logo #i3 {
    width: 60% !important;
    margin: auto;
  }
  #i3 span {
    font-size: 30px !important;
  }
  #logo div {
    z-index: 99999;
    margin-bottom: 30px;
  }
  #s {
    position: absolute !important;
    top: 68px !important;
  }
  #row > .col-lg-4 {
    text-align: center !important;
  }
  #row > .col-lg-4 p {
    padding: 0 !important;
  }
  #h {
    margin-top: 180px;
    height: 280px;
    margin-bottom: 140px;
  }
  .com1 {
    position: absolute !important;
  }
  #harvest {
    margin-left: 11px !important;
  }
  #h > .col-lg-8 > .row {
    height: 300px;
  }
}
.container-fluid {
  margin: 0 !important;
  padding: 0 !important;
}
.navc {
  margin: auto !important;
}
@media (max-width: 450px) {
  #wallet div {
    border-radius: 20px !important;
  }
  .container-fluid {
    padding: 5px !important;
  }
  .navc a {
    width: 50%;
  }
  .row {
    padding: 0;
    margin: 0;
  }
  .container-fluid > svg {
    display: none;
  }
  .fimg {
    padding-left: 10px !important;
  }
  .fdis {
    padding-left: 10px !important;
  }
  .fcon {
    padding-left: 10px !important;
  }
  #harvest {
    /* margin: 0 !important; */
  }
  #header nav {
    padding: 0 !important;
  }
  #header nav div {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  #header nav div div {
    padding: 1px !important;
    width: 154px !important;
    /* font-size: 10px; */
  }
  #header nav div div span {
    padding-left: 5px;
    font-size: 19px;
  }
  #header nav div a {
    margin: 0 !important;
  }
  #logo {
    margin: 0 !important;
  }
  div.navbar-brand {
    width: 40%;
  }
  #crypto {
    font-size: 40px !important;
  }
  .left-u {
    display: none;
  }
  #open {
    margin-bottom: 20px !important;
  }
  #row > .col-lg-4 {
    text-align: center !important;
  }
  #row > .col-lg-4 p {
    padding: 0 !important;
  }
  #wallet {
    height: auto !important;
  }
  #wallet1 .row > .col-lg-4 {
    position: relative !important;
  }
  #wallet2 {
    position: relative !important;
    right: 0 !important;
    margin-top: 10px !important;
  }
  #w {
    position: relative !important;
  }
  .com {
    display: none !important;
  }
  #logo div {
    margin-bottom: 20px !important;
  }
  #i3 {
    /* width: 300px !important; */
    margin: auto;
  }
  #rewardTable .t,
  .a {
    font-size: 12px !important;
  }
  #rewardTable {
    height: 200px !important;
  }
  #i3 span {
    font-size: 25px !important;
  }
  .modal-content .col-lg-11 {
    margin: 0px !important;
  }
  .apy {
    width: 50% !important;
    height: auto !important;
    border: 1px solid;
    border-radius: 2px !important;
    overflow-x: auto !important;
  }
  .apy1 {
    width: 99% !important;
  }
  .deposit div h6 {
    font-size: 20px !important;
  }
  .claim span {
    font-size: 20px !important;
  }
  #reward > h6 {
    padding-left: 0px !important;
  }
}

.farm a {
  color: white;
  width: 33%;
  display: inline-block;
  text-align: center;
  margin: auto;
  font-family: Satoshi;
}

#filterTable a:hover {
  color: white !important;
}
#filterTable table {
  border-radius: 1px;
  cursor: default;
}
#filterTable thead {
  border-bottom: 1px solid var(--Gray-700, #313131);
  color: #a3a3a3;
}
#filterTable tbody {
  height: 38px;
  font-size: 14px;

  &:hover {
    background-color: #131313;
    transition: all 0.18s ease-in-out;
  }
}
#filterTable tbody:not(:last-child) {
  border-bottom: 1px solid var(--Gray-700, #313131);
}
#filterTable th,
td {
  padding: 8px;
  text-align: center;
}
#filterTable th {
  height: 40px;
  padding-block: 1px;
}

#filterTable.dashboard,
#filterTable.portfolio {
  th {
    height: 45px;
    padding: 5px;
    color: white;
    background-color: #313131;
  }

  td {
    padding: 5px;
    background-color: #171717;
  }

  tbody {
    font-size: 16px;
    height: 85px;
  }

  .pagination1 {
    background-color: #797979;
  }
}

#filterTable table p {
  color: var(--Gray-500, #595959);
  font-family: Satoshi;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pool {
  padding-left: 34px;
}
.tab-header {
  background-color: #595959;
  border-radius: 8px !important;
  margin: 10px;
}
.active {
  border-radius: 6px !important;
  background: #ffd300 !important;
  color: black !important;
}
.tab-header button {
  width: 50%;
  border: none;
  outline: none;

  background-color: #595959;
  color: white;
  border-radius: 8px;
}
#tabp {
  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;

  /* background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  /* 1111 */
  /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  */
}
#taba {
  color: var(--Gray-100, #e9e9e9);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  background: var(--Gray-700, #313131);
  line-height: 1;
  text-align: center;
  padding: 5px;
}

#td p {
  margin: 10px 10px;
}
.tj {
  text-align: left;
  color: var(--Yeti-White, #fff);

  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.te {
  text-align: end;
  color: var(--Yeti-White, #fff);
  text-align: right;

  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.apy {
  width: 25%;
  /* height: 100%; */
  border-right: 1px solid;
}
.apy:nth-of-type(4) {
  border: none;
}
.apy h6 {
  /* Heading 6 */
  font-family: Clash Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 5px;
  width: fit-content;
}

.apy p {
  color: var(--Gray-400, #7d7b7b);

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px;
}

.tvl,
.y {
  border: 1px solid #313131;
  border-radius: 6px;
  margin: 5px;
  /* margin: auto; */
  text-align: center;
}

.tvl a {
  width: 25%;
  display: inline-block;
  color: white;
  border-right: 1px solid #313131;
  /* margin: auto; */
}

.tvl a:nth-of-type(3) {
  width: 50% !important;
}

.y a {
  width: 20%;
  display: inline-block;
  color: white;
  border-right: 1px solid #313131;
}

.deposit div {
  border-radius: 21px;
  border: 1px solid #313131;
  background: var(--Gray-900, #171717);
  margin: 5px auto;
  padding: 10px;
}

.deposit div p {
  color: var(--Gray-500, #595959);

  /* Paragraph X-Large */
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.deposit div h6 {
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Heading 4 */
  font-family: Clash Grotesk;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#reward h6 {
  color: var(--Yeti-White, #fff);

  /* Paragraph XX-Large */
  font-family: Satoshi;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.click {
  border-radius: 8px;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
}
#reward > div {
  border-radius: 12px;
  border: 1px solid var(--Gray-700, #313131);
  height: 232px;
}

.claim span {
  /* Heading 4 */
  font-family: Clash Grotesk;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(
    --Gradient,
    linear-gradient(90deg, #a2d254 15.73%, #ffd300 40.73%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.claim a {
  position: absolute;
  right: 10px;
  display: inline-flex;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 60px;
  background: var(
    --Primary-Linear-Gradient,
    linear-gradient(90deg, #a2d254 0%, #ffd300 100%)
  );
  color: black;
}
.claim a:hover {
  color: black !important;
}
.claim p {
  color: var(--Gray-500, #595959);

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.input {
  padding: 0px;
  line-height: 2;
}
.input input {
  border-radius: 12px;
  border: 1px solid var(--Gray-700, #313131);
  width: 100%;
  outline: none;
  background-color: transparent;
  color: white;
  padding-left: 5px;
}
.input span {
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.t {
  color: var(--Gray-500, #595959);

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.a {
  color: var(--Yeti-White, #fff);

  /* Paragraph Large */
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: end;
}

/* {
  border: 5px solid red;

} */
.dropdown1 {
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  max-height: auto;
  position: absolute;
  height: auto;
  width: 142px;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  border-radius: 10px !important;
  background-color: #1a1b1f !important;
  border: 1px solid #ccc0 !important;
  box-shadow: 0 1px #0000000f !important;
}
.dropdown1 a {
  display: block;
  margin: auto;
  text-align: center;
  width: 100%;
  color: white;
  font-family: inherit;
}
.dropdown a:hover {
  background-color: #3a3a3d !important;
  color: #fff;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.deposit1 > h6,
.deposit1 > p {
  text-align: center;
}
@media (max-width: 680px) {
  .farm {
    width: 47%;
    margin: 5px !important;
  }
  .farm a {
    font-size: 10px;
    /* font-weight: bolder; */
  }
  #cf {
    position: relative !important;
    right: 0 !important;
    width: 105px;
  }
  #cf a {
    font-size: 15px;
  }
  /* #filterTable th,
  td {
    font-size: 10px;
  } */
  /* #filterTable table span {
    font-size: 10;
    height: 14px !important;
    width: 14px !important;
  }
  #filterTable table svg,
  #filterTable img {
    height: 10px;
    width: 10px;
  }
  .pool svg {
    height: 20px;
  } */
}

.with:hover {
  color: black !important;
}

.pagination1 li > button {
  appearance: none;
  color: white !important;
}

@media (max-width: 820px) {
  #filterTable table {
    width: 820px !important;
    overflow: auto;
  }
  .input input {
    font-size: 10px;
  }
  .modal-content .col-lg-11 {
    margin: 20px !important;
  }
}

@media (max-width: 1368px) {
  .farm {
    width: 47%;
    margin: 5px !important;
  }
  #cf {
    position: relative !important;
    right: 0 !important;
    width: 105px;
  }
  #tab1 {
  }
  .apy {
    width: 50% !important;
  }
  .apy1 {
    width: 100% !important;
    border: 1px solid !important;
  }
  .farm:nth-of-type(2) {
    width: 47%;
  }
  .farm:nth-of-type(4) {
    width: 47%;
    /* display: none; */
  }
}
@media (max-width: 992px) {
  #c {
    margin: 2px !important;
  }
  .y {
    position: relative !important;
  }
  .tvl {
    margin: 0px !important;
    /* padding: 5px; */
  }
  #brk {
    width: 100%;
  }
  #vault a {
    width: 47% !important;
  }
  .rs {
    margin-left: 0px !important;
  }
}
#priceD th {
  border: 1px solid white !important;
  padding: 5px;
}
#deposit a:hover {
  color: black !important;
}

.cCard {
  border: 2px solid rgb(29, 29, 31);
  border-radius: 12px;
  background-color: #171717;
  /* min-height: 450px; */
  font-family: Clash Grotesk;
  letter-spacing: 1px;
  padding-bottom: 10px;
  height: auto;
}
.cCard label {
  color: white;
  font-size: 16px;
}
.cCard > div {
  flex-shrink: 0;
}
.calculatorForm {
  justify-content: space-between;
  font-family: Clash Grotesk;
}
.calculatorForm input {
  width: 100%;
  background: transparent;
  border: 1px solid rgb(49 49 49);
  border-radius: 5px;
  outline: none;
  color: white;
  height: 35px;
  margin-bottom: 5px;
}
.slct {
  height: 65px;

  border: none;
  margin-top: 10px;
}
.slct > select {
  background: transparent;
  border: 2px solid rgb(49 49 49);
  color: white;
  width: 200px;
  height: 35px;
  outline: none;
  /* margin-left: 10px; */
  border: none;
}

.slct > select > option {
  background: transparent;
  color: black;
  outline: none;
  border: none;
}

.cCard button {
  height: 40px;
  border-radius: 100px;
  margin: auto;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(246, 227, 99);
  color: black;
  font-size: 20px;
  margin-top: 20px;
}
.cCard button:hover {
  color: black;
}
.cCard label {
  margin-bottom: 5px;
}

.b1 {
  border-right: 0.5px solid rgb(71, 77, 87);
}
.btab {
  padding-left: 25px;
  margin-top: 10px;
  height: 20px;
  gap: 15px;
}
.btab > div {
  width: fit-content;
  color: white;
  cursor: pointer;
  padding: 2px;
  font-size: 15px;
  color: gray;
  /* margin-bottom: 10px; */
  /* border: 1px solid red; */
}
.activeT {
  color: white !important;
  border-bottom: 2px solid rgb(246, 227, 99);
  font-family: Clash Grotesk;
}
.asmr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.calc:hover {
  background: linear-gradient(
      45deg,
      rgb(143, 212, 31) 0%,
      rgb(255, 229, 105) 40%
    )
    text;
  -webkit-text-fill-color: transparent;
}
.r1 {
  flex-shrink: 1 !important;
}
.cp {
}

.activeTypeA {
  color: chartreuse !important;
}

.disableD {
  opacity: 0.5;
  cursor: not-allowed;
}

.disableD:hover {
  color: white !important;
  opacity: 0.5;
}

.announcement-container {
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  padding: 2px;
  position: relative;
  white-space: nowrap;
  color: white;
  /* position: absolute; */
}

.announcement-text {
  display: inline-block;
  font-size: 20px;
  white-space: nowrap;
  animation: scroll 30s linear infinite;
  font-family: "Satoshi";
}
.announcement-text:hover {
  color: white !important;
}
.announcement-containerl:hover {
  color: white !important;
}
.announcement-text > a:hover {
  color: white !important;
}
.mob-nav {
  display: none !important;
}

.blur {
  filter: blur(15px);
}

.alert-user {
  border: 1px solid yellow !important;
  color: white;
  font-family: math !important;
  font-size: large;
}
.userCard .show {
  border: 1px solid yellow !important;
  /* border: yellow !important; */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 991px) {
  .r1 {
    width: 98%;
    margin: auto;
  }
  .r1 > .col-lg-7 {
    width: 98%;
    margin: 10px auto;
  }
  .calc {
    right: 0px !important;
  }
}

@media (max-width: 450px) {
  .cp {
    /* border: 1px solid red; */
    width: 100% !important;
  }
  .calc {
    top: 0px;
    right: -2px !important;
  }
  .cCard {
    width: 100%;
    top: 1200px;
    z-index: 99999;
  }
  .asmr {
    width: 100%;
  }
  .mob-nav {
    background-color: black;
    display: flex !important;
  }
}
